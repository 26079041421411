<template>
  <div class="container">
    <div class="transfer">
      <a-form-model class="transfer-form" ref="ruleForm" :model="form" :rules="rules">
        <div class="freeTrial-top">
          {{ $t('FreeTrial.freeCharge') }}
        </div>
        <div class="free-top">
          <a-form-model-item>
            <div class="freeTrial-des">

              <div class="freeTrial-format">
                <div class="freeTrial-format-title"><span class="radius">1</span><span class="freeTrial-title">{{
                  $t('FreeTrial.supportedFormats')
                }}</span><span class="freeTrial-format-tooltip">{{
  $t('FreeTrial.compressedPackage')
}}</span>
                </div>
              </div>
            </div>
          </a-form-model-item>
          <div class="freeTrial-upload-information-div"><span class="radius">2</span><span
              class="freeTrial-upload-information">{{ $t('FreeTrial.receiptInformation') }}</span></div>
          <a-form-model-item prop="uploadername" class="form-model-item" :label="$t('FreeTrial.Uploadername')">
            <a-input :placeholder="$t('FreeTrial.EnterUploadername')" v-model="form.uploadername" class="input-class" />
          </a-form-model-item>
          <a-form-model-item prop="telPhone" class="form-model-item" :label="$t('FreeTrial.Telephone')">
            <a-input :placeholder="$t('FreeTrial.EnterTelephone')" v-model="form.telPhone" class="input-class" />
          </a-form-model-item>
          <a-form-model-item prop="purpose" class="form-model-item" :label="$t('FreeTrial.Purpose')">
            <a-select v-model="form.purpose" :placeholder="$t('FreeTrial.EnterPurpose')" class="input-class"
              :dropdownStyle="{ 'background-color': '#383838' }">
              <a-select-option :value="$t('FreeTrial.OperationPlan')">
                {{ $t('FreeTrial.OperationPlan') }}
              </a-select-option>
              <a-select-option :value="$t('FreeTrial.reconstruction')">
                {{ $t('FreeTrial.reconstruction') }}
              </a-select-option>
              <a-select-option :value="$t('FreeTrial.PSI')">
                {{ $t('FreeTrial.PSI') }}
              </a-select-option>
              <a-select-option :value="$t('FreeTrial.Personalized')">
                {{ $t('FreeTrial.Personalized') }}
              </a-select-option>
              <a-select-option :value="$t('FreeTrial.SurgicalRobot')">
                {{ $t('FreeTrial.SurgicalRobot') }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item prop="patientname" :label="$t('FreeTrial.PaientName')" class="form-model-item">
            <a-input :placeholder="$t('FreeTrial.EnterPaientName')" v-model="form.patientname" class="input-class" />
          </a-form-model-item>
          <a-form-model-item prop="visitingHospital" :label="$t('FreeTrial.Hospital')" class="form-model-item">
            <a-input :placeholder="$t('FreeTrial.EnterVisitingHospital')" v-model="form.visitingHospital"
              class="input-class" />
          </a-form-model-item>
          <a-form-model-item prop="hospitaldepartments" :label="$t('FreeTrial.Department')" class="form-model-item">
            <a-input :placeholder="$t('FreeTrial.EnterDepartment')" v-model="form.hospitaldepartments"
              class="input-class" />
          </a-form-model-item>
          <a-form-model-item prop="visitingDoctor" :label="$t('FreeTrial.Doctor')" class="form-model-item">
            <a-input :placeholder="$t('FreeTrial.EnterDoctor')" v-model="form.visitingDoctor" class="input-class" />
          </a-form-model-item>
          <a-form-model-item prop="selectedTags" :label="$t('FreeTrial.Diagnosis')" class="form-model-item">
            <div class="tagDiv">
              <a-checkable-tag v-for="(tag, tagIndex) in DataList" :key="tagIndex" color="blue"
                :checked="form.selectedTags.indexOf(tag) > -1" @change="checked => handleChange(tag, checked)">{{
                  tag.TagName
                }}</a-checkable-tag>
            </div>
          </a-form-model-item>
          <a-form-model-item prop="verificationCode" class="form-model-item" :label="$t('FreeTrial.Code')">
            <div class="code-class">
              <a-input :placeholder="$t('FreeTrial.EnterCode')" v-model="form.verificationCode"
                class="code-class-input" />
              <span class="getCaptcha" @click="refreshCode">
                <IdentifyCode :identify-code="identifyCode" :content-width="100" :content-height="30"></IdentifyCode>
              </span>
            </div>
          </a-form-model-item>
          <div v-if="isShowInformation">
            <a-form-model-item :label="$t('FreeTrial.Mailbox')" class="form-model-item">
              <a-input :placeholder="$t('FreeTrial.EnterMailbox')" v-model="form.email" class="input-class" />
            </a-form-model-item>
            <a-form-model-item :label="$t('FreeTrial.PatientNumber')" class="form-model-item">
              <a-input :placeholder="$t('FreeTrial.EnterPatientNumber')" v-model="form.patientno" class="input-class" />
            </a-form-model-item>
            <!-- <a-form-model-item :label="$t('FreeTrial.Date')" class="form-model-item">
              <a-date-picker @change="onChange" :value="visitTime" :locale="locale" class="class-date" />
            </a-form-model-item> -->
            <a-form-model-item :label="$t('FreeTrial.Remarks')" class="form-model-item">
              <a-input type="textarea" :placeholder="$t('FreeTrial.EnterRemarks')" v-model="form.otherInformation"
                class="input-class input-class-textArea" />
            </a-form-model-item>
          </div>
          <div class="freeTrial-upload">
            <div class="freeTrial-upload-div"><span class="radius">3</span><span class="freeTrial-upload-title">{{
              $t('FreeTrial.UploadAFile')
            }}</span></div>
            <div>
              <uploader ref="uploader" :options="options" :file-status-text="fileStatusText" :autoStart="false"
                @file-added="onFileAdded" @file-progress="onFileProgress" @file-success="onFileSuccess"
                @file-removed="onFileRemoved" @file-error="onFileError" class="uploader">
                <!-- uploader-unsupport 不支持 HTML5 File API 的时候会显示。
                一般就写在uploader根组件里就行了，遇到低版本的浏览器不兼容组件的时候才会展示。 -->
                <uploader-unsupport></uploader-unsupport>
                <uploader-drop>
                  <!-- <uploader-btn class="upfile"><i class="iconfont icon-upload"></i> 上传文件</uploader-btn>
                    <uploader-btn class="updir" :directory="true"><i class="iconfont icon-dir"></i> 上传文件夹</uploader-btn> -->
                  <div class="clearfix">
                    <uploader-btn class="uploader-btn-copy" :attrs="attrs"><img class="clearfix-img"
                        src="../assets/img/freeTrial/uploadFile.png" alt=""></uploader-btn>
                    <div class="clearfix-text">
                      <div class="UploadFile-text">{{ $t('FreeTrial.UploadFile') }}</div>
                      <div class="clearfix-text-zip">{{ $t('FreeTrial.compressedFile') }}</div>
                    </div>
                  </div>
                </uploader-drop>
                <uploader-list>
                  <!-- <ul class="file-list" slot-scope="props">
                    <li class="file-item" :class="`file-${file.id}`" v-for="file in props.fileList" :key="file.id">
                      <uploader-file :class="'file_' + file.id" ref="files" :file="file" :list="true">
                        <div class="fileListDiv" slot-scope="fileProps">
                          <img src="../assets/img/freeTrial/file.png" alt="" class="fileImg">
                          <div class="fileBetween">
                            <div class="fileUp">
                              <span class="filename">{{ file.name }}</span>
                              <span class="filename">{{ fileProps.formatedSize }}</span>
                            </div>
                            <div class="uploader-file-status">
                              <span v-show="fileProps.status !== 'uploading'">{{ fileProps.statusText }}</span>
                              <span v-show="fileProps.status === 'uploading'">
                                <span>{{ fileProps.progressStyle.progress }}</span>
                                <em>{{ fileProps.formatedAverageSpeed }}</em>
                                <i>{{ fileProps.formatedTimeRemaining }}</i>
                              </span>
                            </div>
                          </div>
                          <img src="../assets/img/freeTrial/nice.png" alt="" v-if="fileProps.isComplete"
                            class="freeTrial-success">
                          <div v-if="fileProps.error" @click="file.retry()">重试</div>
                          <div v-if="fileProps.paused" @click="file.resume()">继续</div>
                          <div v-if="fileProps.isUploading" @click="file.pause()">暂停</div>
                          <div class="fileImg fileClose" v-if="!fileProps.isComplete" @click="file.cancel()" />
                        </div>
                      </uploader-file>
                    </li>
                    <div class="no-file" v-if="!props.fileList.length"><i class="nucfont inuc-empty-file"></i> 请上传文件
                    </div>
                  </ul> -->
                </uploader-list>
              </uploader>
            </div>
          </div>
          <div class="InformationCollection">
            <div class="InformationCollection-one">
              <div>
                <a-checkbox :checked="mandatoryChecked" class="checkboxclass" @change="MandatoryCheckedChange">{{
                  $t('FreeTrial.PrivacyPolicy') }}</a-checkbox><a-icon type="down-circle" @click="ShowMandatory" />
              </div>
              <div v-show="IsShowMandatory" class="information-div">
                <h4>{{ $t('FreeTrial.PrivacyPolicyTitleOne') }}</h4>
                <h5>{{ $t('FreeTrial.PrivacyPolicyTitleTipOne') }}</h5>
                <h5>{{ $t('FreeTrial.PrivacyPolicyTitleTipTwo') }}</h5>
                <h4>{{ $t('FreeTrial.PrivacyPolicyTitleTwo') }}</h4>
                <h5>{{ $t('FreeTrial.PrivacyPolicyTitleTipThree') }}</h5>
                <h5>{{ $t('FreeTrial.PrivacyPolicyTitleTipFour') }}</h5>
                <h4>{{ $t('FreeTrial.PrivacyPolicyTitleThree') }}</h4>
              </div>
            </div>
            <div>
              <div>
                <a-checkbox :checked="optionalChecked" @change="OptionalCheckedChange" class="checkboxclass">{{
                  $t('FreeTrial.commercialPurpose') }}</a-checkbox><a-icon type="down-circle" @click="ShowOptional" />
              </div>
              <div v-show="IsShowOptional" class="information-div">
                <h4>{{ $t('FreeTrial.commercialPurposeTitleOne') }}</h4>
                <h4>{{ $t('FreeTrial.commercialPurposeTitleTwo') }}</h4>
                <h4>{{ $t('FreeTrial.commercialPurposeTitleThree') }}</h4>
              </div>
            </div>
          </div>
          <div class="version" v-show="isShowInformation">
            Version 1.0
          </div>
        </div>
        <div class="free-bottom">
          <div class="openInformation">
            <img src="../assets/img/freeTrial/more.png" alt="" class="openInformation-more" @click="openInformation">
            <a-button type="primary" @click="onSubmit" class="submit-class" :loading="loading" :disabled="disabled">
              {{ loading ? $t('FreeTrial.Submiting') : $t('FreeTrial.Submit') }}</a-button>
            <!-- <img src="../assets/img/freeTrial/helpPdf.png" alt="" class="openInformation-more"> -->
          </div>
          <div class="openInformation-progress">
            <a-progress class="freeTrial-progress" v-if="visible" :stroke-color="{
              '0%': '#24E8A6',
              '100%': '#07CBD5',
            }" :percent="percent" />
            <a-progress class="freeTrial-progress" v-show="errorVisible" strokeColor="#D85555" :percent="percent" />
          </div>
        </div>
      </a-form-model>
    </div>
    <div class="logo">
      {{ $t('Common.companyName') }}
    </div>
    <img src="../assets/img/freeTrial/logo.png" alt="" class="freeTrial-logo">
    <a-modal :visible="modelvisible" :title="$t('FreeTrial.WorkOrderNumber')" @ok="handleOk" @cancel="handleOk"
      :okText="$t('FreeTrial.Confirm')" :cancelText="$t('FreeTrial.Cancel')" :maskClosable="false" :closable="false">
      {{ SheetNO }}
    </a-modal>
  </div>
</template>

<script>
import axios from 'axios'
import SparkMD5 from 'spark-md5';
import { message } from 'ant-design-vue'
import { getTag, postForm, uploadfile } from '@/api/role'
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
import IdentifyCode from "vue-identifycode";
export default {
  name: 'FreeTrial',
  data() {
    return {
      agreecommercialuse: 1,//是否勾选用户信息是否可以用于营销以及商业用途
      disabled: false,//同意并上传按钮是否可点击
      mandatoryChecked: true,//必选（用户消息收集）
      optionalChecked: true,//可选（用户消息收集）
      IsShowMandatory: false,//是否展示必选信息
      IsShowOptional: false,//是否展示可选信息
      filepaths: '',//文件上传成功返回的文件path值
      percent: 0,//进度条数值
      loading: false, //防止重复点击提交按钮
      DataList: [], //诊断标签列表
      SheetNO: "", //工单号
      modelvisible: false, //表单上传成功展示工单号弹窗
      visible: false, //自定义进度条显示
      errorVisible: false, //自定义错误进度条显示
      identifyCode: '', //验证码
      isShowInformation: false, //是否展示更多信息
      locale,
      moment,
      visitTime: null,
      options: {
        target: uploadfile(),
        singleFile: true, //单文件上传，覆盖式，如果选择了多个会把之前的覆盖掉
        directory: false, //是否是文件夹上传
        testChunks: false,//是否开启服务器分片校验
        chunkSize: 1048576,  //1MB
        simultaneousUploads: 1, //并发上传数
        forceChunkSize: true, //是否强制所有的块都是小于等于 chunkSize 的值
        maxChunkRetries: 3, //最大自动失败重试上传次数
        chunkRetryInterval: 5000, //重试间隔，null表示立即重试
        // 认为响应式成功的响应码
        successStatuses: [200, 201, 202],
        // 认为是出错的响应码
        permanentErrors: [404, 415, 500, 501],
        // headers: {
        //   'X-token': 'abcd123',
        // },
        processParams(params) {//自定义每一次分片传给后台的参数，params是该方法返回的形参，包含分片信息,
          return {
            //返回一个对象，会添加到每一个分片的请求参数里面
            index: params.chunkNumber,
            md5: params.identifier,
            action: 'upload',
            filename: params.filename,
          };
        },
        parseTimeRemaining(timeRemaining, parsedTimeRemaining) { //上传剩余时间 格式化时间
          return parsedTimeRemaining
            .replace(/\syears?/, '年')
            .replace(/\days?/, '天')
            .replace(/\shours?/, '小时')
            .replace(/\sminutes?/, '分钟')
            .replace(/\sseconds?/, '秒')
        }
      },
      attrs: {
        accept: '.zip,.rar'//限制上传类型
      },
      statusTextMap: {
        success: this.$t('FreeTrial.Success'),
        error: this.$t('FreeTrial.Error'),
        uploading: this.$t('FreeTrial.Uploading'),
        paused: this.$t('FreeTrial.Paused'),
        waiting: this.$t('FreeTrial.Waiting'),
        cmd5: this.$t('FreeTrial.Cmd5')
      },
      fileStatusText: (status, response) => {
        return this.statusTextMap[status];
      },
      form: {
        uploadername: "",//填写者姓名
        telPhone: '', //填写者电话
        purpose: undefined, //手术计划
        verificationCode: '', //验证码
        email: '',//邮箱
        patientno: "", //患者号
        patientname: '', //患者姓名
        visitTime: '', //日期
        visitingHospital: '', //就诊医院
        hospitaldepartments: "",//就诊科室
        visitingDoctor: '', //就诊医生
        selectedTags: [], //已选择的诊断标签
        otherInformation: '' //其他信息
      },
      rules: {
        telPhone: [{
          required: true,
          pattern: /^1[34578]\d{9}$/,
          message: this.$t('FreeTrial.EnterTelephone'),
          trigger: 'change'
        }],
        visitingHospital: [{ required: true, message: this.$t('FreeTrial.EnterVisitingHospital'), trigger: 'change' }],
        hospitaldepartments: [{ required: true, message: this.$t('FreeTrial.EnterDepartment'), trigger: 'change' }],
        purpose: [{ required: true, message: this.$t('FreeTrial.EnterPurpose'), trigger: 'change' }],
        uploadername: [{ required: true, message: this.$t('FreeTrial.EnterUploadername'), trigger: 'change' }],
        patientname: [{ required: true, message: this.$t('FreeTrial.EnterPaientName'), trigger: 'change' }],
        visitingDoctor: [{ required: true, message: this.$t('FreeTrial.EnterDoctor'), trigger: 'change' }],
        selectedTags: [{ type: 'array', required: true, message: this.$t('FreeTrial.SelectDiagnosis'), trigger: 'change' }],
        verificationCode: [{ required: true, message: this.$t('FreeTrial.EnterCode'), trigger: 'change' },
        {
          validator: (rule, value, callback) => {
            if (this.identifyCode == value.toUpperCase()) {
              callback()
            } else {
              return callback(new Error())
            }
          }, message: this.$t('FreeTrial.ErrorCode')
        }]
      }
    };
  },
  components: { IdentifyCode },
  mounted() {
    this.identifyCode = this.createCode();
  },
  created() {
    this.getTags();
  },
  methods: {
    //增加文件时的回调
    onFileAdded(file) {
      // 计算MD5
      this.computeMD5(file);
    },
    //计算MD5
    computeMD5(file) {
      let blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice,
        chunkSize = 1048576,
        chunks = Math.ceil(file.size / chunkSize),
        currentChunk = 0,
        spark = new SparkMD5.ArrayBuffer(),
        fileReader = new FileReader();
      let time = new Date().getTime();
      // console.log('开始计算MD5...');
      file.cmd5 = true;
      fileReader.onload = (e) => {
        spark.append(e.target.result);   // Append array buffer
        currentChunk++;
        if (currentChunk < chunks) {
          // console.log(`第${currentChunk}分片解析完成, 开始第${currentChunk + 1} / ${chunks}分片解析`);
          // let percent = Math.floor(currentChunk / chunks * 100);
          // file.cmd5progress = percent;
          loadNext();
        } else {
          let md5 = spark.end();
          // console.log(`MD5计算完成：${file.name} \nMD5：${md5} \n分片：${chunks} 大小:${file.size} 用时：${new Date().getTime() - time} ms`);
          spark.destroy(); //释放缓存
          file.uniqueIdentifier = md5; //将文件md5赋值给文件唯一标识
          file.cmd5 = false; //取消计算md5状态
          file.resume(); //开始上传
        }
      };
      fileReader.onerror = () => {
        console.warn('oops, something went wrong.');
        file.cancel();
      };
      let loadNext = () => {
        let start = currentChunk * chunkSize,
          end = ((start + chunkSize) >= file.size) ? file.size : start + chunkSize;
        fileReader.readAsArrayBuffer(blobSlice.call(file.file, start, end));
      };
      loadNext();
    },
    // 文件进度的回调
    onFileProgress(rootFile, file, chunk) {
      // console.log(`上传中 ${file.name}，chunk：${chunk.startByte / 1024 / 1024} ~ ${chunk.endByte / 1024 / 1024}`);
    },
    //当所有分片上传后，执行的方法。
    onFileSuccess(rootFile, file, response, chunk) {
      let that = this;
      let fetchForm = new FormData();
      fetchForm.append("totalchunks", file.chunks.length); // 分片总数
      fetchForm.append("filename", file.name); // 文件名称
      fetchForm.append("md5", file.uniqueIdentifier); // 文件 hash
      fetchForm.append("action", 'merge');
      axios.post(uploadfile(), fetchForm).then(function (res) {
        // console.log(response);
        if (res.data.Result === 200) {
          that.filepaths = res.data.Path;
        } else if (res.data.Result === 417) {
          //合并失败强制清除文件列表，提示重新上传
          const uploaderInstance = that.$refs.uploader.uploader;
          uploaderInstance.cancel(); //移除文件
          that.filepaths = '';
          message.error(this.$t('FreeTrial.ErrorUploadFile'), 5);
        }
      })
        .catch(function (error) {
          // console.log(error);
        });
    },
    //一个文件（文件夹）被移除
    onFileRemoved: function (file) {
      this.filepaths = ''
    },
    //文件上传失败，文件还未传输到后端
    onFileError(rootFile, file, response, chunk) {
      // console.log('文件上传失败回调');
      // console.log('Error:', response)
    },
    //获取选中的诊断Tags
    handleChange(tag, checked) {
      const nextSelectedTags = checked
        ? [...this.form.selectedTags, tag]
        : this.form.selectedTags.filter(t => t !== tag);
      this.form.selectedTags = nextSelectedTags;
    },
    //获取诊断标签Tags
    getTags() {
      let that = this;
      getTag().then(res => {
        if (res.Result == 200) {
          that.DataList = res.DataList;
        }
      }).catch((error) => {

      })
    },
    //清空信息
    handleOk() {
      this.form.uploadername = "";
      this.form.telPhone = "";
      this.form.purpose = undefined;
      this.form.verificationCode = "";
      this.form.email = "";
      this.form.patientno = "";
      this.form.patientname = "";
      this.visitTime = null;
      this.form.visitTime = "";
      this.form.visitingHospital = "";
      this.form.hospitaldepartments = "";
      this.form.visitingDoctor = "";
      this.form.selectedTags = [];
      this.form.otherInformation = "";
      this.filepaths = '';
      this.modelvisible = false;
      const uploaderInstance = this.$refs.uploader.uploader;
      uploaderInstance.cancel(); //移除文件
    },
    //提交信息
    onSubmit() {
      let that = this;
      that.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (!that.filepaths) {
            message.error(this.$t('FreeTrial.PleaseUploadFile'), 5);
            return false;
          }
          that.loading = true;
          const formData = new FormData();
          let diagnosticsitetags = '';
          formData.append('uploadername', that.form.uploadername);
          formData.append('patientname', that.form.patientname);
          formData.append('telphone', that.form.telPhone);
          formData.append('email', that.form.email);
          formData.append('purpose', that.form.purpose);
          formData.append('visittime', that.form.visitTime);
          formData.append('visitinghospital', that.form.visitingHospital);
          formData.append('visitingdoctor', that.form.visitingDoctor);
          formData.append('hospitaldepartments', that.form.hospitaldepartments);
          formData.append('otherinformation', that.form.otherInformation);
          formData.append('patientno', that.form.patientno);
          formData.append('verificationcode', that.form.verificationCode);
          formData.append('agreecommercialuse', that.agreecommercialuse);
          formData.append('filepaths', that.filepaths);
          that.form.selectedTags.forEach((item, index) => {
            if (index === that.form.selectedTags.length - 1) {
              diagnosticsitetags += item.TagUid
            } else {
              diagnosticsitetags += item.TagUid + ','
            }
          })
          formData.append('diagnosticsitetags', diagnosticsitetags);
          postForm(formData).then(res => {
            if (res.Result == 200) {
              that.SheetNO = res.SheetNO;
              that.loading = false;
              that.modelvisible = true;
            } else {
              that.loading = false;
              message.error(res.Message, 5);
            }
          }).catch((error) => {
            that.loading = false;
          })
        } else {
          return false;
        }
      });
    },
    //时间信息
    onChange(date, dateString) {
      this.visitTime = dateString;
      this.form.visitTime = dateString;
    },
    //生成随机数
    random(min, max) {
      return parseInt(Math.random() * (max - min + 1) + min);
    },
    //生成验证码
    createCode() {
      let code = "";
      const codeLength = 4; // 验证码的长度
      const random = [2, 3, 4, 5, 6, 7, 8, 9, "A", "B", "C", "D", "E", "F", "G", "H", "J", "K", "L", "M", "N", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"]; // 随机数
      for (let i = 0; i < codeLength; i++) {
        // 循环操作
        const index = Math.floor(Math.random() * 32); // 取得随机数的索引（0~35）
        code += random[index]; // 根据索引取得随机数加到code上
      }

      return code;
    },
    //刷新验证码
    refreshCode() {
      this.identifyCode = this.createCode();
    },
    //是否展示更多信息
    openInformation() {
      this.isShowInformation = !this.isShowInformation;
    },
    MandatoryCheckedChange(e) {
      this.mandatoryChecked = e.target.checked;
      this.disabled = !e.target.checked;
    },
    ShowMandatory() {
      this.IsShowMandatory = !this.IsShowMandatory;
    },
    OptionalCheckedChange(e) {
      this.optionalChecked = e.target.checked;
      this.agreecommercialuse = e.target.checked ? 1 : 0;
    },
    ShowOptional() {
      this.IsShowOptional = !this.IsShowOptional;
    },
  },
}
</script>
<style scoped lang="less">
h3,
h4 {
  color: #D6D6D6;
  line-height: 1.2;
}

h5 {
  margin: 2px 12px;
  color: #D6D6D6;
}

.information-div {
  line-height: 1.2;
  margin: 0 30px;
}

.InformationCollection-one {
  margin: 0 0 6px 0;
}

.checkboxclass {
  font-weight: bolder;
}

.InformationCollection {
  margin: 10px 24PX 0;
  text-align: left;
}

.version {
  margin: 4px 0;
  color: #333333;
}

.fileImg {
  display: inline-block;
  width: 20px;
  height: 24px;
  margin: auto 0;
}

.freeTrial-success {
  width: 16px;
  height: 16px;
  margin: auto 0
}

.uploader-drop {
  background-color: transparent;
  border: none;
  height: 90px;
  padding: 0;
}

.uploader {
  width: 100%;
  padding: 0;
  margin: 10px auto 0;
  font-size: 16px;

  .uploader-btn {
    margin-right: 4px;
    color: #fff;
    padding: 6px 16px;
  }

  .upfile {
    border: 1px solid #409eff;
    background: #409eff;
  }

  .updir {
    border: 1px solid #67c23a;
    background: #67c23a;
  }

  .uploader-list {
    overflow: auto;
    overflow-x: hidden;
    overflow-y: auto;
    font-size: 18px;

    /deep/li {
      margin-top: 6px;
      height: 40px;
      line-height: 40px;
    }

    /deep/.uploader-file {
      height: 40px;
      line-height: 40px;
      border: 0;
    }

    /deep/.uploader-file-name {
      width: 24%;
      height: 100%;
      text-align: left;
      line-height: 40px;
      text-indent: 6px;
    }

    /deep/.uploader-file-size {
      width: 20%;
      text-align: left;
      height: 100%;
      line-height: 40px;
      text-indent: 6px;
    }

    /deep/.uploader-file-meta {
      display: none;
      line-height: 40px;
      text-indent: 6px;
    }

    /deep/.uploader-file-status {
      width: 40%;
      height: 100%;
      text-align: left;
      line-height: 40px;
      text-indent: 6px;
    }

    /deep/.uploader-file-actions {
      display: flex;
      width: 16%;
      height: 100%;
      align-items: center;
      text-indent: 6px;
    }

    /deep/.uploader-file-actions>span {
      margin-top: 0;
      overflow: hidden;
    }

    /deep/.uploader-file-icon {
      display: none;
    }

    /deep/.uploader-file-progress {
      background: #3aa17f;
    }

    /deep/.iconfont {
      font-size: 18px;
      color: #de1f26;
    }

    /deep/.no-file {
      text-align: center;
      font-size: 16px;
      padding-top: 50px;
      color: #de1f26;
    }
  }

}

.uploader-btn-copy {
  border: none;
}

.uploader-btn:hover {
  background: transparent;
}


.tagDiv {
  text-align: left;
}

/deep/.ant-tag-checkable {
  color: #d6d6d6;
  font-size: 22px;
  margin: 4px;
  padding: 4px;
  background-color: #424242;
}

/deep/.ant-tag-checkable:hover {
  color: #d6d6d6;
}

/deep/.ant-tag-checkable-checked {
  color: #389e0d;
  background: #f6ffed;
  font-size: 22px;
  border: 1px solid #b7eb8f;
  padding: 4px;
  margin: 4px;
}

.freeTrial-top {
  height: 60px;
  line-height: 60px;
  text-align: center;
  font-size: 24px;
  color: #FFFFFF;
  font-weight: bold;
  border-bottom: #22DCA1 1px solid;
}

.freeTrial-top-center {
  font-size: 20px;
  color: #FFFFFF;
  line-height: 30px;
}

.underline-text-div {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.underline-text-line {
  position: relative;
  font-size: 20px;
}

.freeTrial-format {
  text-align: left;
  padding: 0 24px;
}

.underline-text-line-line {
  height: 2px;
  width: 75%;
  background: #22DCA1;
  position: absolute;
  bottom: -2px;
  left: 0;
}

.freeTrial-title {
  font-size: 22px;
  color: #FFFFFF;
  font-weight: bold;
}

.freeTrial-upload {
  padding: 0 24px;
}

.freeTrial-upload-title {
  font-size: 22px;
  color: #FFFFFF;
  font-weight: bold;
}

.freeTrial-upload-div {
  display: flex;
  align-items: center;
  text-align: left;
}

.freeTrial-upload-information-div {
  display: flex;
  align-items: center;
  text-align: left;
  padding: 0 24px 6px 24px;
}

.freeTrial-upload-information {
  font-size: 22px;
  color: #FFFFFF;
  font-weight: bold;
}

.openInformation-more {
  width: 40px;
  height: 40px;
}

.underline-text-div-center {
  color: #C9C5B9;
  font-size: 20px;
}


.underline-text-div-right {
  color: #FFFFFF;
  font-size: 20px;
  margin-left: 6px;
}

.freeTrial-format-tooltip {
  font-size: 20px;
  color: #FFFFFF;
}

.underline-text {
  font-size: 20px;
  color: #FFF;
}

.underline-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.freeTrial-format-title {
  display: flex;
  align-items: center;
  text-align: left;
  margin: 10px 0;
}

.form-model-item {
  padding: 0 24px;
}

.form-model-item-file {
  margin-bottom: 0 !important;
}

/deep/.ant-form-item {
  display: flex;
}

/deep/.ant-form-item-label {
  width: 110px;

  label {
    color: #D6D6D6;
    font-size: 18px;
  }
}

/deep/.ant-form-item-control-wrapper {
  flex: 1;
}

.fileList-div {
  // max-height: 80px;
  overflow-y: auto;
}

/deep/.ant-upload.ant-upload-drag .ant-upload {
  padding: 0;
}

.clearfix-img {
  cursor: pointer;
  display: inline-block;
  width: 60px;
  margin: 0 10px;
}

.clearfix-text {
  text-align: left;
  font-size: 16px;
  color: #22DCA1;
}

.UploadFile-text {
  font-size: 22px;
}

.clearfix-text-zip {
  text-align: left;
  line-height: 1.2;
  margin-top: 8px;
  font-size: 18px;
  text-shadow: 0 0 0px #22DCA1, 0 0 0px #22DCA1, 0 0 0px #22DCA1, 0 0 00px #22DCA1;
}

.radius {
  display: inline-block;
  width: 24px;
  height: 24px;
  line-height: 16px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  border-radius: 50%;
  border: 3px solid #FFF;
}

/deep/.ant-modal-content {
  position: absolute;
  left: -300px;
  top: 300px;
  background: #2c2c2c;
}

/deep/.ant-modal-body {
  width: 500px;
  height: 100px;
  line-height: 3;
  text-align: center;
  font-size: 20px;
}

/deep/.ant-modal-footer {
  border: 0;
}

/deep/.ant-modal-header {
  background: #424242;
  border-bottom: 0;
}

/deep/.ant-modal-title {
  font-size: 20px;
  color: #FFFFFF;
  text-align: left;
}


/deep/.ant-upload.ant-upload-drag {
  width: 460px;
  margin: 16px auto 0;
}

/deep/.ant-upload.ant-upload-drag .ant-upload-drag-container {
  width: 460px;
  height: 100px;
  margin: 16px auto 0;
  border-radius: 6px;
}

/deep/.ant-upload.ant-upload-drag .ant-upload-drag-container::hover {
  border-radius: 6px;
}

.clearfix {
  // width: 440px;
  height: 100%;
  display: flex;
  align-items: center;
  background: linear-gradient(360deg, rgba(36, 232, 166, 0.1) 0%, rgba(7, 203, 213, 0) 100%);
  border: 2px dashed rgba(36, 232, 166, 0.4);
  border-radius: 6px;
  margin: 0;
}

.input-class {
  display: inline-block;
}

.input-class-textArea {
  min-height: 100px;
}

.code-class {
  position: relative;
  display: flex;
  justify-content: space-between;
}

.getCaptcha {
  display: inline-block;
  cursor: pointer;
  margin-left: 24px;
}

/deep/ #s-canvas {
  width: 126px;
  height: 38px;
}

/deep/ .s-canvas {
  width: 126px;
  height: 38px;
}

/deep/ .has-error .ant-form-explain,
.has-error .ant-form-split {
  float: left;
}

/deep/ .class-date {
  width: 100%;
}

.freeTrial-logo {
  position: absolute;
  height: 100px;
  top: 24px;
  left: 58px;
}

.submit-class {
  // width: 160px;
  height: 46px;
  font-size: 18px;
  border-radius: 6px;
  background: #22DCA1;
}

.submit-class:disabled {
  opacity: 0.3;
  color: #212121;
}

.submit-class[disabled]:hover {
  opacity: 0.3;
  background: #22DCA1;
}

.transfer {
  padding: 0;
  width: 570px;
  height: 880px;
  border-radius: 8px;
  background: rgba(77, 77, 74, 0.9);
  box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.5);
  position: absolute;
  right: 40px;
  top: 10px;
}

.transfer-form {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.free-top {
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
}

.container {
  width: 100%;
  height: 100vh;
  background-image: url("../assets/img/freeTrial/background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar {
  width: 8px;
  height: 8px
}

/*定义滚动条轨道*/
::-webkit-scrollbar-track {
  background-color: transparent;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  background-color: #333333;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}

/deep/ .ant-collapse>.ant-collapse-item>.ant-collapse-header {
  padding: 0;
}

/deep/ .ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow svg {
  opacity: 0;
}

.openInformation {
  display: flex;
  justify-content: space-between;
  padding: 12px 30px;
  cursor: pointer;
  width: 100%;
  color: #d6d6d6;
  align-items: center;
}

/deep/ .anticon svg {
  color: #d6d6d6;
}

/deep/ .openInformation .anticon svg {
  color: #4DB6AC;
}

/deep/ .ant-btn:hover {
  border: 1px solid #4DB6AC;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.5), 0 4px 4px 0 rgba(0, 0, 0, 0.5);
}

/deep/ .ant-progress {
  width: 230px;
  line-height: 0 !important;
}

/deep/ .ant-progress-bg {
  height: 6px !important;
}

/deep/.ant-select-selection-selected-value,
/deep/.ant-select-selection__placeholder {
  font-size: 18px;
}

/deep/ .ant-input {
  height: 40px;
  font-size: 18px;
  border-radius: 4px;
  padding: 4px 18px;
}

/deep/ .ant-select-selection {
  height: 40px;
}

/deep/ .ant-select-selection__rendered {
  line-height: 40px;
  margin-left: 18px;

}

/deep/ .ant-form-item {
  margin-bottom: 6px;
  line-height: 1;
}

.star {
  color: #D85555;
  position: absolute;
  top: -8px;
  left: 6px;
  z-index: 10;
}

.codestar {
  color: #D85555;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  margin-left: 6px;
  z-index: 1;
}

.logo {
  position: fixed;
  bottom: 24px;
  right: 30px;
  color: #000000;
  font-size: 16px;
}

/deep/.ant-progress-text {
  position: absolute;
  display: none;
  top: -16px;
  right: 14px;
}

// /deep/ .ant-progress-text {
//   display: none;
// }

/deep/ .ant-progress-show-info .ant-progress-outer {
  margin-right: 0;
  padding-right: 0;
}

.fileListDiv {
  width: 100%;
  display: flex;
  margin: auto;
  height: 40px;
  display: flex;
}

.fileListDiv:hover {
  background: rgba(255, 255, 255, 0.1);
}

.fileBetween {
  flex: 1;
  display: flex;
  display: space-around;
  line-height: 1;
}

.fileUp {
  line-height: 1;
  display: flex;
  justify-content: space-between;
}

.filename {
  display: inline-block;
  max-width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 50px;
}

.fileClose {
  width: 18px;
  height: 18px;
  background: url("../assets/img/freeTrial/closenoselected.png") no-repeat;
  background-size: cover;
}

.fileClose:hover {
  background: url("../assets/img/freeTrial/close.png") no-repeat center;
  background-size: cover;
  cursor: pointer;
}

/deep/ .ant-progress-line {
  position: fixed !important;
  right: 120px !important;
  top: 877px !important;
  width: 570px;
}

/deep/ .ant-progress-inner {
  background: #464646 !important;
}

/deep/ .ant-progress-inner,
/deep/ .ant-progress-success-bg,
/deep/ .ant-progress-bg {
  border-radius: 0 !important;
}

/deep/ .ant-select-selection-selected-value {
  line-height: 36px;
}

@media screen and (max-width: 768px) {
  .free-top {
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .freeTrial-top {
    height: 30px;
    line-height: 30px;
    font-size: 10px;
    text-align: center;
    padding: 0 10px;
    border-bottom: #22DCA1 1px solid;
  }

  .freeTrial-top-center {
    font-size: 8px;
    color: #FFFFFF;
    line-height: 12px;
  }

  .underline-text-div {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .underline-text-line {
    position: relative;
    font-size: 8px;
  }

  .freeTrial-format {
    text-align: left;
    padding: 0 8px;
  }

  .underline-text-line-line {
    height: 2px;
    width: 75%;
    background: #22DCA1;
    position: absolute;
    bottom: -2px;
    left: 0;
  }

  .freeTrial-title {
    font-size: 8px;
    color: #FFFFFF;
    font-weight: bold;
  }

  .freeTrial-upload {
    padding: 6px 8px 0 8px;
  }

  .freeTrial-upload-title {
    font-size: 8px;
    color: #FFFFFF;
    font-weight: bold;
  }

  .freeTrial-upload-div {
    display: flex;
    align-items: center;
    text-align: left;
  }

  .freeTrial-upload-information-div {
    display: flex;
    align-items: center;
    text-align: left;
    padding: 0 8px 4px;
  }

  .freeTrial-upload-information {
    font-size: 8px;
    color: #FFFFFF;
    font-weight: bold;
  }

  .openInformation-more {
    width: 20px;
    height: 20px;
  }

  .underline-text-div-center {
    color: #C9C5B9;
    font-size: 10px;
  }

  .underline-text-div-right {
    color: #FFFFFF;
    font-size: 10px;
    margin-left: 6px;
  }

  .freeTrial-format-tooltip {
    font-size: 8px;
    color: #FFFFFF;
  }

  .underline-text {
    font-size: 8px;
    color: #FFF;
  }

  .underline-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .freeTrial-format-title {
    display: flex;
    align-items: center;
    text-align: left;
    margin: 8px 0;
  }

  .form-model-item {
    padding: 0 10px;
  }

  .form-model-item-file {
    margin-bottom: 0 !important;
  }

  .fileList-div {
    margin-top: 10px;
    // max-height: 40px;
    overflow-y: auto;
  }

  .clearfix-img {
    cursor: pointer;
    display: inline-block;
    width: 25px;
    margin: 0 6px;
  }

  .clearfix-text {
    font-size: 8px;
    color: #22DCA1;
  }

  .radius {
    display: inline-block;
    width: 8px;
    height: 8px;
    line-height: 6px;
    font-size: 6px;
    font-weight: bold;
    text-align: center;
    border-radius: 4px;
    border: 1px solid #FFF;
  }

  .clearfix {
    width: 90vw;
    height: 50px;
    display: flex;
    align-items: center;
    background: linear-gradient(360deg, rgba(36, 232, 166, 0.1) 0%, rgba(7, 203, 213, 0) 100%);
    border: 2px dashed rgba(36, 232, 166, 0.4);
    border-radius: 6px;
    margin: auto 0;
  }

  .input-class {
    display: inline-block;
  }

  .input-class-textArea {
    height: 30px;
  }

  .code-class {
    position: relative;
    display: flex;
    justify-content: space-between;
  }

  .getCaptcha {
    display: inline-block;
    cursor: pointer;
    margin-left: 10px;
  }

  /deep/ #s-canvas {
    width: 60px;
    height: 18px;
  }

  /deep/ .s-canvas {
    width: 60px;
    height: 18px;
  }

  /deep/ .has-error .ant-form-explain,
  .has-error .ant-form-split {
    float: left;
  }

  /deep/ .class-date {
    width: 100%;
  }

  .freeTrial-logo {
    display: none;
    position: absolute;
    height: 40px;
    top: 24px;
    left: 58px;
  }

  .submit-class {
    width: 60px;
    height: 20px;
    padding: 0;
    font-size: 10px;
    border-radius: 3px;
    background: #22DCA1 !important;
  }

  .transfer {
    padding: 0;
    width: 100%;
    height: 100vh;
    border-radius: 0;
    background: rgba(77, 77, 74, 0.9);
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
    position: absolute;
    right: 0;
    top: 0;
  }

  .transfer-form {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .container {
    width: 100%;
    height: 100vh;
    background-image: url("../assets/img/freeTrial/background.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  /*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px
  }

  /*定义滚动条轨道*/
  ::-webkit-scrollbar-track {
    background-color: transparent;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
  }

  /*定义滑块 内阴影+圆角*/
  ::-webkit-scrollbar-thumb {
    background-color: #333333;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
  }

  /deep/ .ant-collapse>.ant-collapse-item>.ant-collapse-header {
    padding: 0;
  }

  /deep/ .ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow svg {
    opacity: 0;
  }

  .openInformation {
    display: flex;
    justify-content: space-between;
    padding: 12px 10px;
    cursor: pointer;
    width: 100vw;
    color: #d6d6d6;
    align-items: center;
  }

  /deep/ .anticon svg {
    color: #d6d6d6;
  }

  /deep/ .openInformation .anticon svg {
    color: #4DB6AC;
  }

  /deep/ .ant-btn:hover {
    border: 1px solid #4DB6AC;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.5), 0 4px 4px 0 rgba(0, 0, 0, 0.5);
  }

  /deep/ .ant-progress {
    width: 230px;
    line-height: 0 !important;
  }

  /deep/ .ant-progress-bg {
    height: 3px !important;
  }

  /deep/ .ant-input {
    height: 20px;
    font-size: 8px;
    border-radius: 2px;
    padding: 4px 10px;
  }

  /deep/ .ant-select-selection {
    font-size: 8px;
    height: 20px;
  }

  /deep/ .ant-select-selection__rendered {
    line-height: 26px;
    margin-left: 10px;
  }

  /deep/ .ant-form-item {
    margin-bottom: 0;
    line-height: 1;
  }

  .star {
    color: #D85555;
    position: absolute;
    top: 8px;
    left: 3px;
    z-index: 10;
  }

  .codestar {
    color: #D85555;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    margin-left: 6px;
    z-index: 1;
  }

  .logo {
    display: none;
    position: fixed;
    bottom: 24px;
    right: 30px;
    color: #000000;
    font-size: 16px;
  }

  /*/deep/.ant-progress-text {
    position: absolute;
    top: -16px;
    right: 14px;
  }*/
  /deep/ .ant-progress-text {
    display: none;
  }

  /deep/ .ant-progress-show-info .ant-progress-outer {
    margin-right: 0;
    padding-right: 0;
  }

  .fileListDiv {
    width: 100%;
    display: flex;
    height: 20px;
    justify-content: space-around;
  }

  .fileListDiv:hover {
    background: rgba(255, 255, 255, 0.1);
  }

  .fileBetween {
    flex: 1;
    line-height: 1;
  }

  .fileUp {
    line-height: 1;
    font-size: 8px;
    display: flex;
    justify-content: space-between;
  }

  .filename {
    display: inline-block;
    max-width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 20px;
  }

  /deep/.ant-form-item-control {
    line-height: 1;
  }

  .fileClose {
    width: 10px;
    height: 10px;
    background: url("../assets/img/freeTrial/closenoselected.png") no-repeat;
    background-size: cover;
  }

  .fileClose:hover {
    background: url("../assets/img/freeTrial/close.png") no-repeat center;
    background-size: cover;
    cursor: pointer;
  }

  /deep/ .ant-progress-line {
    position: fixed !important;
    right: 0 !important;
    top: 90% !important;
    width: 100%;
  }

  /deep/ .ant-progress-inner {
    background: #464646 !important;
  }

  /deep/ .ant-progress-inner,
  /deep/ .ant-progress-success-bg,
  /deep/ .ant-progress-bg {
    border-radius: 0 !important;
  }

  /deep/ .ant-select-selection-selected-value {
    line-height: 20px;
  }

  .jiaohu {
    display: none;
  }

  .ant-tag {
    line-height: 1;
  }

  /deep/.ant-tag-checkable {
    color: #d6d6d6;
    font-size: 8px;
    margin: 2px;
    padding: 2px;
    background-color: #424242;
  }

  /deep/.ant-tag-checkable:hover {
    color: #d6d6d6;
  }

  /deep/.ant-tag-checkable-checked {
    color: #389e0d;
    background: #f6ffed;
    font-size: 8px;
    border: 1px solid #b7eb8f;
    padding: 2px;
    margin: 2px;
  }

  /deep/.ant-select-selection__placeholder {
    font-size: 8px;
    top: 35%;
  }

  /deep/.ant-form-item-label {
    width: 60px;
    label {
      vertical-align: top;
      display: inline-block;
      height: 20px;
      line-height: 20px;
      color: #D6D6D6;
      font-size: 8px;
    }
  }

  .clearfix-text-zip {
    text-align: left;
    line-height: 1;
    margin-top: 4px;
    font-size: 8px;
    text-shadow: 0 0 0px #22DCA1, 0 0 0px #22DCA1, 0 0 0px #22DCA1, 0 0 00px #22DCA1;
  }

  .UploadFile-text {
    font-size: 10px;
  }

  .information-div {
    line-height: 1.2;
    margin: 0 30px;
  }

  .InformationCollection-one {
    margin: 0 0 4px 0;
  }

  .InformationCollection {
    margin: 6px 4PX 0 6px;
    text-align: left;
    font-size: 8px;
  }

  /deep/.ant-checkbox-wrapper {
    font-size: 6px;
  }

  /deep/ form input[type='checkbox'] {
    width: 10px;
    height: 10px;
  }

  /deep/.ant-checkbox-inner {
    width: 10px;
    height: 10px;
  }

  /deep/.ant-checkbox-inner::after {
    width: 4px;
    height: 6px;
  }

  .uploader-drop {
    background-color: transparent;
    border: none;
    height: 54px;
    padding: 0;
  }

  .uploader {
    width: 100%;
    padding: 0;
    margin: 10px auto 0;
    font-size: 10px;

    .uploader-btn {
      margin-right: 4px;
      color: #fff;
      padding: 0px;
    }

    .upfile {
      border: 1px solid #409eff;
      background: #409eff;
    }

    .updir {
      border: 1px solid #67c23a;
      background: #67c23a;
    }

    .uploader-list {
      overflow: auto;
      overflow-x: hidden;
      overflow-y: auto;
      font-size: 8px;

      /deep/li {
        margin-top: 2px;
        height: 16px;
        line-height: 16px;
      }

      /deep/.uploader-file {
        height: 16px;
        line-height: 16px;
        border: 0;
      }

      /deep/.uploader-file-name {
        width: 24%;
        height: 100%;
        text-align: left;
        line-height: 16px;
        text-indent: 6px;
      }

      /deep/.uploader-file-size {
        width: 20%;
        text-align: left;
        height: 100%;
        line-height: 16px;
        text-indent: 6px;
      }

      /deep/.uploader-file-meta {
        display: none;
        line-height: 16px;
        text-indent: 6px;
      }

      /deep/.uploader-file-status {
        width: 40%;
        height: 100%;
        text-align: left;
        line-height: 16px;
        text-indent: 6px;
      }

      /deep/.uploader-file-actions {
        display: flex;
        width: 16%;
        height: 100%;
        align-items: center;
        text-indent: 6px;
      }

      /deep/.uploader-file-actions>span {
        margin-top: 0;
        overflow: hidden;
      }

      /deep/.uploader-file-icon {
        display: none;
      }

      /deep/.uploader-file-progress {
        background: #3aa17f;
      }

      /deep/.iconfont {
        font-size: 8px;
        color: #de1f26;
      }

      /deep/.no-file {
        text-align: center;
        font-size: 8px;
        padding-top: 20px;
        color: #de1f26;
      }
    }

  }

  /deep/.ant-checkbox+span {
    padding: 0 2px;
  }

  .openInformation-progress {
    display: none;
  }

  /deep/ .has-error .ant-form-explain,
  .has-error .ant-form-split {
    font-size: 8px;
  }

  /deep/.ant-form-explain,
  .ant-form-extra {
    min-height: 14px;
    height: 14px;
    line-height: 14px;
  }

  /deep/.ant-form-item-required::before {
    position: relative;
    top: 6px;
  }

  /deep/ .anticon svg {
    font-size: 8px;
  }

  /deep/.ant-select-selection-selected-value {
    font-size: 8px !important;
  }
}
</style>